body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div {
  font-family: 'Poppins', sans-serif !important;
}
span {
  font-family: 'Poppins', sans-serif !important;
}
.material-icons {
  font-family: 'Material Icons' !important;
}
.MuiFormLabel-root {
  font-family: 'Poppins', sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiPickersToolbarText-toolbarTxt {
  color: #ffffff8a !important;
}
.MuiPickersClockNumber-clockNumberSelected {
  color: #fff !important;
}
.MuiPickersToolbarText-toolbarBtnSelected {
  color: #fff !important;
}
.MuiPickersDay-daySelected {
  color: #fff !important;
}
.MuiPickersClockPointer-thumb {
  background-color: #fff !important;
}
